<template>
  <!-- <client-only> -->
  <Swiper
    :pagination="{
      clickable: true,
    }"
    :autoplay="sliderAutoplay"
    :modules="modules"
    aria-label="My Favorite Images"
    :slides-per-view="1"
    :space-between="5"
    class="h-auto md:h-[600px]"
    :class="pageClass"
  >
    <SwiperSlide v-for="item in data?.slides" :key="item.id">
      <NuxtLink
        v-if="item?.buttonUrl"
        class="flex h-full flex-col"
        :class="{ 'flex-col-reverse': data.bottomMobile }"
        :to="item?.buttonUrl"
      >
        <div
          class="flex h-full flex-col"
          :class="{ 'flex-col-reverse': data.bottomMobile }"
          @click="onClick(item?.buttonUrl)"
        >
          <div
            :class="{
              'max-md:aspect-h-1 max-md:aspect-w-1': !props.blockIndex,
              'h-[300px]': props.blockIndex,
            }"
            class="cursor-pointer md:h-full"
            :style="
              brightnessFilter(item)
                ? { filter: `brightness(${brightnessFilter(item)}%)` }
                : null
            "
          >
            <UiLazyImg
              class="h-full w-full"
              :src="item?.file?.url || ''"
              :src-mobile="item?.fileMobile?.url || ''"
              :alt="
                item?.file?.alt ||
                item.title ||
                removeHTMLTagFromString(item.text) ||
                'Image'
              "
              cover
              :no-lazy="!blockIndex"
            />
          </div>
          <div
            class="w-full"
            :style="[
              mdAndDown
                ? {
                    backgroundColor:
                      item?.backgroundColorMobile?.hexa ||
                      item?.backgroundColor?.hexa ||
                      '#F4EEE8',
                  }
                : {},
            ]"
          >
            <div
              class="container inset-0 flex h-full items-center absolute justify-end py-4 md:absolute md:justify-end md:py-4"
            >
              <div
                class="w-full h-full content"
                @click="contentLink"
                v-html="item.text"
              />
            </div>
          </div>
        </div>
      </NuxtLink>
      <!-- <NuxtLink
        class="flex h-full flex-col"
        :class="{ 'flex-col-reverse': data.bottomMobile }"
        :to="item?.buttonUrl"
      > -->
      <div
        v-else
        class="flex h-full flex-col"
        :class="{ 'flex-col-reverse': data.bottomMobile }"
        @click="onClick(item?.buttonUrl)"
      >
        <div
          :class="{
            'max-md:aspect-h-1 max-md:aspect-w-1': !props.blockIndex,
            'h-[300px]': props.blockIndex,
          }"
          class="cursor-pointer md:h-full"
          :style="
              brightnessFilter(item)
                ? { filter: `brightness(${brightnessFilter(item)}%)` }
                : null
            "
        >
          <UiLazyImg
            class="h-full w-full"
            :src="item?.file?.url || ''"
            :src-mobile="item?.fileMobile?.url || ''"
            :alt="
              item?.file?.alt ||
              item.title ||
              removeHTMLTagFromString(item.text) ||
              'Image'
            "
            cover
            :no-lazy="!blockIndex"
          />
        </div>
        <div
          class="w-full"
          :style="[
            mdAndDown
              ? {
                  backgroundColor:
                    item?.backgroundColorMobile?.hexa ||
                    item?.backgroundColor?.hexa ||
                    '#F4EEE8',
                }
              : {},
          ]"
        >
          <div
            class="container inset-0 flex h-full items-center justify-end py-4 md:absolute md:justify-end md:py-4"
            :class="{'max-lg:absolute max-lg:py-4': data.overlayForMobile}"
          >
            <div
              class="w-full h-full content"
              @click="contentLink"
              v-html="item.text"
            />
          </div>
        </div>
      </div>
      <!-- </NuxtLink> -->
    </SwiperSlide>
  </Swiper>
  <!-- </client-only> -->
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper";
import "swiper/css/pagination";
import { BlockSliderContent } from "ecom-types";
import { useAppBreakpoints } from "~/composables";

const route = useRoute();
const router = useRouter();
const localePath = useLocalePathPolyfill();
const contentLink = useContentLink;
const props = withDefaults(
  defineProps<{
    data: BlockSliderContent;
    blockIndex?: number;
  }>(),
  {
    blockIndex: -1,
  },
);

const modules = [Pagination, Autoplay];

const { mdAndDown, lgAndUp } = useAppBreakpoints();
const { brightnessFilter } = useBrightnessFilter(lgAndUp);

const pageClass = computed(() => {
  const slug = route.path.slice(1)?.split("/")?.join("-") || "home";
  return slug + "-" + props.blockIndex;
});

const sliderAutoplay = computed(() => {
  return pageClass.value === "home-0"
    ? {
        delay: 50000,
        disableOnInteraction: false,
      }
    : false;
});

function onClick(url?: string) {
  if (url) {
    router.push(localePath(url));
  }
}
</script>

<style scoped>
.swiper :deep(.swiper-pagination) {
  position: absolute;
  bottom: 10px;
}
.swiper :deep(.swiper-pagination-bullet) {
  height: 13px;
  width: 13px;
}
.swiper :deep(.swiper-pagination-bullet) {
  height: 13px;
  width: 13px;
  opacity: 1;
  @apply bg-transparent border border-white transition-all duration-500;
}
.swiper :deep(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
  @apply bg-white border border-transparent;
}

.swiper.home-0 :deep(.swiper-pagination) {
  @apply absolute bottom-auto top-[96vw] -translate-y-full md:bottom-[30px] md:top-auto md:translate-y-0;
}
.swiper.home-0 :deep(.swiper-pagination-bullet) {
  @apply h-2.5 w-2.5 opacity-100 bg-transparent border border-white;
}
.swiper.home-0
  :deep(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
  @apply bg-white border border-transparent;
}

.content:deep(.button) {
  @apply max-w-fit outline-0 flex items-center justify-center text-white bg-primary hover:bg-neutral-black-800 transition-all text-sm px-[25px] py-4 uppercase;
}
.content:deep(.button.outline) {
  @apply !text-primary !bg-transparent border !border-primary hover:!bg-primary hover:!text-white;
}
</style>
